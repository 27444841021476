import { useState } from 'react'

import { useToasts } from '@/hooks/useToasts'
import { SubmitHandler } from 'react-hook-form'

import {
  useCreateCaseMutation,
  useFacilityNamesQuery,
} from '@/graphql/generated/hooks'
import { getFacilityOptions } from '@/utils/forms/selectOptions'

import { CaseFormInputsIProps } from '../types/types'

export const useCreateCase = ({ onClose }) => {
  const [createCase] = useCreateCaseMutation()
  const [isLoading, setIsLoading] = useState(false)
  const { showSuccess, showError } = useToasts()

  const { data: facilitiesData } = useFacilityNamesQuery({
    fetchPolicy: 'network-only',
  })

  const facilityOptions = getFacilityOptions(facilitiesData)

  const onCreateCase: SubmitHandler<CaseFormInputsIProps> = async (values) => {
    const facilityIds = values.facilities?.map((f) => f.value) || []
    setIsLoading(true)
    try {
      const { data } = await createCase({
        variables: {
          input: {
            description: values?.description,
            facilityIds,
            name: values?.name,
          },
        },
        refetchQueries: ['Cases'],
      })
      const caseReturnedName = data.createCase?.case?.name
      showSuccess(`Successfully created ${caseReturnedName}`)
      setIsLoading(false)
      onClose()
    } catch (error) {
      showError(error.message)
      setIsLoading(false)
    }
  }

  return {
    facilitiesData,
    facilityOptions,
    isLoading,
    onCreateCase,
  }
}
