import { useCreateCase } from '../hooks/useCreateCase'
import { usePermissions } from '@/hooks/usePermissions'
import { useForm } from 'react-hook-form'
import { HiOutlineCheckCircle } from 'react-icons/hi'

import {
  Box,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
} from '@chakra-ui/react'

import { CaseManagement } from '@/components/icons'
import {
  Button,
  FormInputControl,
  FormInputSelectControl,
  FormTextareaControl,
} from '@/components/ui'

interface CreateCaseModalIProps {
  isOpen: boolean
  onClose: () => void
}

interface FormInputsIProps {
  name: string
  facilities?: { label: string; value: string }[]
  description?: string
}

const CaseManagementIcon = () => (
  <Icon as={CaseManagement} boxSize='32px' strokeColor='#353849' />
)

export const CreateCaseModal = ({ isOpen, onClose }: CreateCaseModalIProps) => {
  const { shouldEnableCaseManagement } = usePermissions()
  const { onCreateCase, isLoading, facilityOptions } = useCreateCase({
    onClose,
  })

  const { handleSubmit, register, control, errors } = useForm<FormInputsIProps>(
    {
      reValidateMode: 'onChange',
    }
  )

  return (
    <Modal
      isOpen={isOpen}
      motionPreset='slideInBottom'
      onClose={onClose}
      size='xl'
    >
      <ModalOverlay />
      <ModalContent bg='#fff'>
        <ModalHeader>
          <Box align-items='center' display='flex' m='20px auto'>
            <Box display='grid' placeItems='center'>
              <CaseManagementIcon />
            </Box>
            <Box
              color='#353849'
              fontSize='26px'
              fontWeight='extrabold'
              letterSpacing='-0.4px'
              ml={2}
            >
              Create Case
            </Box>
            <Spacer />
            <ModalCloseButton color='#000' opacity={0.4} position='initial' />
          </Box>
        </ModalHeader>
        <ModalBody p={0}>
          <Box px={6} py={2}>
            <Box mb={3}>
              <FormInputControl
                errorMessage={errors.name && errors.name.message}
                id='name'
                inputRef={register({
                  required: 'Case name is required',
                })}
                isDisabled={isLoading}
                isInvalid={!!errors.name}
                label='Case Name'
                placeholder='Enter a name for this Case'
              />
            </Box>
            <Box mb={3}>
              <FormInputSelectControl
                closeMenuOnSelect={false}
                control={control}
                data-testid='CreateCaseModal:facilities'
                defaultValue={null}
                errorMessage={!!errors?.facilities && 'Facilities are required'}
                id='facilities'
                isClearable
                isDisabled={isLoading}
                isInvalid={!!errors.facilities}
                isMulti
                isSearchable
                label='Facilities'
                options={facilityOptions}
                placeholder='Add facilities where this Case applies'
                rules={{ required: true }}
              />
            </Box>
            <Box mb={3}>
              <FormTextareaControl
                data-testid='createCaseModal_description'
                errorMessage={errors.description && errors.description.message}
                id='description'
                isInvalid={!!errors.description}
                label='Brief Summary'
                labelFontWeight='500'
                placeholder='Enter brief summary'
                textareaRef={register({
                  required: 'Brief summary is required',
                })}
              />
            </Box>
          </Box>
        </ModalBody>
        <ModalFooter pb={6} py={4}>
          <Button mr={3} onClick={onClose} variant='secondary'>
            Cancel
          </Button>
          <Button
            isDisabled={!shouldEnableCaseManagement}
            isLoading={isLoading}
            leftIcon={<HiOutlineCheckCircle size='24px' />}
            loadingText='Creating Case'
            onClick={handleSubmit(onCreateCase)}
          >
            Create Case
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
