import { useMemo } from 'react'

import { useCreateModal } from '../hooks/useCreateModal'
import { FeatureFlag, useFeatureFlags } from '@/hooks/useFeatureFlags'
import { useLiveStreamSetup } from '@/hooks/useLiveStreamSetup'
import { usePermissions } from '@/hooks/usePermissions'
import { useRouter } from 'next/compat/router'
import { BiListUl } from 'react-icons/bi'
import { HiOutlineExclamation, HiOutlineOfficeBuilding } from 'react-icons/hi'
import { MdOutlineRouter } from 'react-icons/md'
import { RiShieldFlashLine } from 'react-icons/ri'
import { useSelector } from 'react-redux'

import { Flex, Icon } from '@chakra-ui/react'

import { CaseManagement, Floor } from '@/components/icons'
import { PACS } from '@/components/icons'
import { Rule } from '@/components/icons'
import { CreateCaseModal } from '@/features/case-create'
import { CreateDeviceModal } from '@/features/device-crud'
import { CreateFacilityModal } from '@/features/facility-crud'
import { CreateFloorModal } from '@/features/floor-crud'
import { CreateGatewayModal } from '@/features/gateway-crud'
import { CreateIncidentModal } from '@/features/incident-create'
import { CreatePACSCredentialsModal } from '@/features/pacs-crud'
import { CreateSOPModal } from '@/features/sop-crud'
import { selectEditMode } from '@/redux/ui/uiSlice'

import { CreateModalOptions, CreateModalType } from '../types/types'
import { CreateButtonDropdown } from './CreateButtonDropdown'

export const FloorIcon = () => <Icon as={Floor} boxSize='24px' />
export const PACSIcon = () => (
  <Icon as={PACS} boxSize='20px' strokeColor='#fff' />
)
export const RuleIcon = () => <Icon as={Rule} boxSize='32px' />
export const CaseManagementIcon = () => (
  <Icon as={CaseManagement} boxSize='22px' />
)

export const CreateDropdown = () => {
  const isEditMode = useSelector(selectEditMode)
  const {
    shouldEnableCreateDevice,
    shouldEnableCreateFacility,
    shouldEnableCreateFloor,
    shouldEnableCreateGateway,
    shouldEnableCreatePACS,
    shouldEnableCreateIncidentsRule,
    shouldEnableCreateSOP,
    shouldEnableCaseManagement,
  } = usePermissions()
  const { hasNoLiveStream, isCamio, isOrangePeel } = useLiveStreamSetup()
  const { openedModal, isOpen, openModal, closeModal } = useCreateModal()
  const router = useRouter()
  const flags = useFeatureFlags()
  const isPACSSourceSecretsEnabled = flags?.[FeatureFlag.pacsSourceSecrets]
  const isIncidentRulesEnabled = flags?.[FeatureFlag.incidentRules]
  const isCaseManagementEnabled = flags?.[FeatureFlag.caseManagement]

  const createModalOptions: CreateModalOptions = useMemo(
    () => [
      {
        key: 'incident',
        label: 'Incident',
        component: CreateIncidentModal,
        icon: HiOutlineExclamation,
        disabled: false,
        hidden: false,
      },
      {
        key: 'facility',
        label: 'Facility',
        component: CreateFacilityModal,
        icon: HiOutlineOfficeBuilding,
        disabled: !shouldEnableCreateFacility || !isEditMode,
        hidden: false,
      },
      {
        key: 'floor',
        label: 'Floor',
        component: CreateFloorModal,
        icon: FloorIcon,
        disabled: !shouldEnableCreateFloor || !isEditMode,
        hidden: false,
      },
      {
        key: 'device',
        label: 'Device',
        component: CreateDeviceModal,
        icon: RiShieldFlashLine,
        disabled: !shouldEnableCreateDevice || !isEditMode,
        hidden: hasNoLiveStream || isCamio,
      },
      {
        key: 'sop',
        label: 'SOP',
        component: CreateSOPModal,
        icon: BiListUl,
        disabled: !shouldEnableCreateSOP || !isEditMode,
        hidden: false,
      },
      {
        key: 'gateway',
        label: 'Gateway',
        component: CreateGatewayModal,
        icon: MdOutlineRouter,
        disabled: !shouldEnableCreateGateway || !isEditMode,
        hidden: hasNoLiveStream || isCamio,
      },
      {
        key: 'pacs',
        label: 'PACS',
        component: CreatePACSCredentialsModal,
        icon: PACSIcon,
        disabled: !shouldEnableCreatePACS || !isEditMode,
        hidden:
          !shouldEnableCreatePACS ||
          !isOrangePeel ||
          !isPACSSourceSecretsEnabled,
      },
      {
        key: 'rule',
        label: 'Rule',
        component: null,
        icon: RuleIcon,
        disabled: !isEditMode,
        hidden: !shouldEnableCreateIncidentsRule || !isIncidentRulesEnabled,
      },
      {
        key: 'case',
        label: 'Case',
        component: CreateCaseModal,
        icon: CaseManagementIcon,
        disabled: !isEditMode,
        hidden: !shouldEnableCaseManagement || !isCaseManagementEnabled,
      },
    ],
    [isEditMode, shouldEnableCreatePACS, shouldEnableCaseManagement]
  )

  const OpenedModal = createModalOptions.find(
    (option) => option.key === openedModal
  )?.component

  const handleModalAction = (modalType: CreateModalType) => {
    if (modalType === 'rule') {
      router.push('/create-incident-rule')
    } else {
      openModal(modalType)
    }
  }
  return (
    <>
      <Flex alignItems='center'>
        <CreateButtonDropdown
          createModalOptions={createModalOptions}
          openModal={handleModalAction}
        />
      </Flex>
      {openedModal !== null && (
        <OpenedModal isOpen={isOpen} onClose={closeModal} />
      )}
    </>
  )
}
